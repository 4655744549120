import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { BgImage, Container, Layout, Section } from "../components"
import SEO from "../components/seo"

const ContactPage = props => {
  const { data } = props

  return (
    <Layout transparentHeader>
      <SEO title="Contact" />
      <Section>
        <BgImage
          fluid={data.desktop.childImageSharp.fluid}
          height="100vh"
          mobileHeight="100vh"
          title="forest"
          overlayColor="rgba(0, 0, 0, 0.5)"
        >
          <Container>
            <Grid>
              <H1>Got it!</H1>
              <h1
                css={`
                  color: var(--gray-300);
                  text-align: center;
                `}
              >
                I'll hit you back soon.
              </h1>
            </Grid>
          </Container>
        </BgImage>
      </Section>
    </Layout>
  )
}

const Grid = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-12%);
`

const H1 = styled.h1`
  font-size: 5rem;
  color: var(--gray-300);
  text-align: center;
`

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "trees.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ContactPage
